import React, { useEffect, useState } from "react";
import CultureMinistryLogo from "../../assets/images/culture_ministry_logo.svg";
import HeydarAliyevCenterLogo from "../../assets/images/heydar_aliyev_center_logo.svg";
import EducationMinistryLogo from "../../assets/images/education_ministry_logo.svg";
import KidsArtFestLogo from "../../assets/images/kids_art_fest_logo.svg";
import html2canvas from "html2canvas";
import { jsPDF } from "jspdf";
import styles from "./index.module.css";
import registrationCheck from "../../api/registrationCheck";
import finalResults from "../../api/finalResults";
import Loading from "../../components/loading";
import { BackArrow } from "../../icons";
import { Link } from "react-router-dom";
import Wrapper from "../../components/UI/wrapper";

const FinalResultDetails = () => {
  const [regType, setRegType] = useState(null);
  const [data, setData] = useState(false);
  const [loading, setLoading] = useState(false);

  const pdfHandler = () => {
    const divToDisplay = document.getElementById("pdf");
    if (window.innerWidth < 500) {
      html2canvas(divToDisplay).then(function (canvas) {
        const divImage = canvas.toDataURL("image/png");
        const pdf = new jsPDF({
          unit: "in",
          format: [12, 32],
          compress: true,
        });
        pdf.addImage(divImage, "PNG", 0, 0);
        pdf.save(`${data?.ad_soyad}.pdf`);
      });
    } else if (window.innerWidth < 1280) {
      html2canvas(divToDisplay).then(function (canvas) {
        const divImage = canvas.toDataURL("image/png");
        const pdf = new jsPDF({
          unit: "in",
          format: [15, 30],
          compress: true,
        });
        pdf.addImage(divImage, "PNG", 0, 0);
        pdf.save(`${data?.ad_soyad}.pdf`);
      });
    } else {
      html2canvas(divToDisplay).then(function (canvas) {
        const divImage = canvas.toDataURL("image/png");
        const pdf = new jsPDF({
          unit: "in",
          format: [20, 15],
          compress: true,
        });
        pdf.addImage(divImage, "PNG", 0, 0);
        pdf.save(`${data?.ad_soyad}.pdf`);
      });
    }
  };
  useEffect(() => {
    const userData = JSON.parse(sessionStorage.userData);
    async function fetchData() {
      // const { data } = await registrationCheck.getTimePlace(userData);
      const { data } = await finalResults.getFinalResults(userData);
      setData(data);
      setLoading(true);
    }
    fetchData();
    setRegType(userData.type);
  }, []);
  return (
    <>
      {loading ? (
        <>
          <Wrapper>
            <Link to={"/final-result"} className={styles["go-back"]}>
              <BackArrow />
              <p>Geri qayıt</p>
            </Link>

            {regType == "personal" && data?.qalibi_yoxla == 0 ? (
              <div className={styles["text-message"]}>
                <h2>Hörmətli iştirakçı</h2>
                <br />
                <p>
                  Azərbaycan xalqının Ümummilli Lideri Heydər Əliyevin 100 illik
                  yubileyinə həsr edilən Uşaq İncəsənət Festivalında iştirak
                  etdiyiniz üçün sizə təşəkkür edirik!
                </p>
                <p>
                  Təəssüf ki, Siz "Final mərhələ"si üzrə yekun qiymətləndirmənin
                  nəticələrinə uyğun olaraq bu mərhələdən keçə bilməmisiniz.
                  Əziz iştirakçı, minlərlə iştirakçı arasından finala qədər
                  yüksəlmək özü böyük bir nailiyyətdir! Növbəti
                  festivallarımızda yenidən görüşmək ümidi ilə sizə uğurlar arzu
                  edirik!
                </p>
                <p>
                  Ən xoş arzularla,
                  <br /> Uşaq İncəsənət Festivalının Təşkilat Komitəsi
                </p>
              </div>
            ) : null}

            {regType == "personal" && data?.qalibi_yoxla == 1 ? (
              <div className={styles["text-message"]}>
                <p>
                  Hörmətli <b>{data.ad_soyad}</b>!
                </p>
                <p>
                  Azərbaycan xalqının Ümummilli Lideri Heydər Əliyevin 100 illik
                  yubileyinə həsr edilən Uşaq İncəsənət Festivalında iştirak
                  etdiyiniz üçün sizə təşəkkür edirik!
                </p>
                <p>
                  Siz, final mərhələsi üzrə yekun qiymətləndirmənin nəticələrinə
                  uyğun olaraq <b>{data.yas_kateqoriyasi}</b> yaş qrupunda{" "}
                  <b>"{data.nominasiya}"</b>
                  nominasiyası üzrə <b>"{data.bacariq_seviyyesi}"</b>{" "}
                  kateqoriyasında <b>{data.yer}</b> yerə layiq görülmüsünüz.
                </p>
                <p>
                  Əlamətdar qələbəniz münasibəti ilə sizi ürəkdən təbrik edirik!
                  Qoy bu qələbə daha çox zəfərlər qazanmaq üçün Sizə uğur
                  gətirsin.
                </p>
                <p>
                  Ən xoş arzularla, <br /> Uşaq İncəsənət Festivalının Təşkilat
                  Komitəsi
                </p>
              </div>
            ) : null}

            {regType == "collective" && data?.qalibi_yoxla == 0 ? (
              <div className={styles["text-message"]}>
                <h2>Hörmətli Kollektiv!</h2>
                <br />
                <p>
                Azərbaycan xalqının Ümummilli Lideri Heydər Əliyevin 100 illik yubileyinə həsr edilən Uşaq İncəsənət Festivalında iştirak etdiyiniz üçün sizə təşəkkür edirik!
                </p>
                <p>
                Təəssüf ki, Siz "Final mərhələ"si üzrə yekun qiymətləndirmənin nəticələrinə uyğun olaraq bu mərhələdən keçə bilməmisiniz. Əziz kollektiv üzüvləri, minlərlə iştirakçı arasından finala qədər yüksəlmək özü böyük bir nailiyyətdir! Növbəti festivallarımızda yenidən görüşmək ümidi ilə sizə uğurlar arzu edirik!
                </p>
                <p>
                Ən xoş arzularla,
                  <br /> Uşaq İncəsənət Festivalının 
Təşkilat Komitəsi
                </p>
              </div>
            ) : null}

            {regType == "collective" && data?.qalibi_yoxla == 1 ? (
              <div className={styles["text-message"]}>
                <p>Hörmətli <b>{data.kollektivin_adi}</b> kollektivi!</p>
                <p>Azərbaycan xalqının Ümummilli Lideri Heydər Əliyevin 100 illik yubileyinə həsr edilən Uşaq İncəsənət Festivalında iştirak etdiyiniz üçün sizə təşəkkür edirik!</p>
                <p>Siz, final mərhələsi üzrə yekun qiymətləndirmənin nəticələrinə uyğun olaraq <b>{data.yas_kateqoriyasi}</b> yaş qrupunda <b>"{data.nominasiya}"</b>  nominasiyası üzrə <b>{data.yer} </b> yerə layiq görülmüsünüz. </p>
                <p>Əlamətdar qələbəniz münasibəti ilə sizi ürəkdən təbrik edirik! Qoy bu qələbə daha çox zəfərlər qazanmaq üçün Sizə uğur gətirsin.</p>
                <p>
                  Ən xoş arzularla, <br /> Uşaq İncəsənət Festivalının Təşkilat
                  Komitəsi
                </p>
              </div>
            ) : null}
          </Wrapper>
          {data?.bal === 1 ? (
            <>
              <div className={styles["text-message"]}>
                <h2>Hörmətli iştirakçı</h2>
                <br />
                <br />
                <p>
                  Siz festivalın ikinci mərhələsi üzrə yekun qiymətləndirmənin
                  nəticələrinə uyğun olaraq final mərhələsində iştirak etmək
                  hüququ qazanmısınız. Qeyd olunan mərhələdə iştirakınıza dair
                  müvafiq məlumatlarla aşağıdakı cədvəl vasitəsilə tanış ola
                  bilərsiniz.
                  <br />
                  <br />
                  Final mərhələsində uğurlar arzu edirik!
                </p>
              </div>
              <div className={styles["wrapper"]} id="pdf">
                <div className={styles["inner-container"]}>
                  <header className={styles["header"]}>
                    <div className={styles["logo"]}>
                      <h1>
                        Ümummilli lider Heydər Əliyevin <br />
                        100 illiyinə həsr edilən <br />
                        Uşaq İncəsənət Festivalı
                      </h1>
                      <div>
                        <img src={KidsArtFestLogo} alt="" />
                      </div>
                    </div>
                    <div className={styles["partners"]}>
                      <img src={CultureMinistryLogo} alt="" />
                      <img src={HeydarAliyevCenterLogo} alt="" />
                      <img src={EducationMinistryLogo} alt="" />
                    </div>
                  </header>
                  <h2 className={styles["title"]}>
                    İştirak məlumatları və qaydaları
                  </h2>
                  <main className={styles["main"]}>
                    {regType === "collective" ? (
                      <div>
                        <p>Kollektivin adı</p>
                        <p>{data?.kollektivin_adi}</p>
                      </div>
                    ) : null}
                    <div>
                      {regType === "personal" ? (
                        <p>Ad, Soyad</p>
                      ) : (
                        <p>Kollektiv rəhbərinin adı, soyadı</p>
                      )}
                      <p>{data?.ad_soyad}</p>
                    </div>
                    <div>
                      {regType === "personal" ? (
                        <p>İştirakçı kodu</p>
                      ) : (
                        <p>Kollektivin kodu</p>
                      )}
                      <p>{data?.kod}</p>
                    </div>
                    <div>
                      <p>Qeydiyyatdan keçdiyi rayon/şəhər</p>
                      <p>{data?.rayon}</p>
                    </div>
                    <div>
                      <p>Nominasiya</p>
                      <p>{data?.nominasiya}</p>
                    </div>
                    <div>
                      <p>Müsabiqənin keçiriləcəyi gün</p>
                      <p>{data?.gun}</p>
                    </div>
                    <div>
                      <p>Vaxt</p>
                      <p>{data?.vaxt}</p>
                    </div>
                    <div>
                      <p>Məkan</p>
                      {data?.mekan ? (
                        <>
                          <div className={styles["text-right-div"]}>
                            <p>{data?.mekan[0]}</p>
                            <p>{data?.mekan[1]}</p>
                          </div>
                        </>
                      ) : (
                        <p></p>
                      )}
                    </div>
                    {/* <div>
                  <p>Məlumat üçün əlaqə nömrəsi</p>
                  <p>{data?.nomre}</p>
                </div> */}
                  </main>
                  <footer>
                    <h3 className={styles["h3"]}>
                      İştirakçılar müsabiqə məkanı ilə bağlı saytdan məlumat
                      çıxaranda aşağıda bu mətn əks olunacaq:
                    </h3>
                    <h3 className={styles["h3"]}>İştirak qaydaları:</h3>
                    <ol className={styles["ol"]}>
                      <li>
                        İştirakçılar müsabiqə vaxtından 15 dəqiqə əvvəl müsabiqə
                        məkanına gəlməlidirlər.
                      </li>
                      <li>
                        İştirakçılar müsabiqə məkanına gəldikdə özləri ilə bu
                        formanı kağız və ya elektron formatda, eləcə də
                        şəxsiyyətlərini təsdiq edən sənədi (şəxsiyyət vəsiqəsi,
                        doğum haqqında şəhadatnamə və ya xarici vətəndaş sənədi)
                        gətirməlidirlər.
                      </li>
                      <li>
                        Müsabiqədə iştirak çərçivəsində, həmçinin gözləmə zamanı
                        ümumi etik qaydalara riayət olunmalıdır. Müsabiqə
                        keçirilən məkanda digər iştirakçılara maneçilik
                        törətməmək məqsədilə yüksək səslə danışmaq, gülmək, məşq
                        etmək qadağandır.
                      </li>
                      <li>
                        İştirakçının ifa edəcəyi musiqi aləti öncədən
                        köklənməlidir.
                      </li>
                      <li>
                        Müsabiqədə iştirak zamanı iştirakçıların geyimi
                        nominasiyaların formatına uyğun olmalı, xüsusi tələb
                        olmadığı halda isə səliqəli formada olmalıdır (ağ-qara).
                        Geyim və digər qaydalara dair daha ətraflı məlumat almaq
                        üçün{" "}
                        <a href="https://uif.az/" target="_blank">
                          www.uif.az
                        </a>{" "}
                        saytının “Məlumat” bölməsi ilə tanış olmaq tövsiyyə
                        olunur.
                      </li>
                    </ol>
                  </footer>
                </div>
              </div>
              <div className={styles["download-container"]}>
                {/* <h3>
            Xahiş edirik aşağıdakı "İstirakçi məlumat vərəqəsini" yükləyərək
            iştirak qaydalarına dair ətraflı məlumatlarla taniş olasınız:
          </h3> */}
                <div className={styles["next-container"]}>
                  <button
                    type="button"
                    className={styles["next"]}
                    onClick={() => pdfHandler()}
                  >
                    Faylı yüklə
                  </button>
                </div>
                {/* <div className={styles["inputs"]}>
              <div className={styles["email"]}>
                <label htmlFor="">Faylı emailə göndər:</label>
                <input type="email" placeholder="E-mail" />
                <button
                  type="button"
                  className={styles["next"]}
                  style={{ paddingRight: 3 + "px", paddingLeft: 3 + "px" }}
                >
                  Göndər
                </button>
              </div>
            </div> */}
              </div>
            </>
          ) : (
            <h2 className={styles["title"]}>{data?.message}</h2>
          )}
        </>
      ) : (
        <Loading />
      )}
    </>
  );
};

export default FinalResultDetails;
