import React, { useState } from "react";
import Wrapper from "../UI/wrapper";
import styles from "./index.module.css";
import CultureMinistryLogo from "../../assets/images/culture_ministry_logo_white.svg";
import HeydarAliyevCenterLogo from "../../assets/images/heydar_aliyev_center_logo_white.svg";
import EducationMinistryLogo from "../../assets/images/education_ministry_logo_white.svg";
import {
  FacebookLogo,
  InstagramLogo,
  TwitterLogo,
  YoutubeLogo,
  PhoneIcon,
  WhatsAppLogo,
} from "../../icons";
import Facebook from "../../icons/socialIcons/facebook.svg";
import Instagram from "../../icons/socialIcons/instagram.svg";
import Twitter from "../../icons/socialIcons/twitter.svg";
import Youtube from "../../icons/socialIcons/youtube.svg";
import Tiktok from "../../icons/socialIcons/tiktok.svg";
import AgreementPopup from "../agreementPopup";
const Footer = () => {
  const [popup, setPopup] = useState(false);
  const getLink = () => {
    return process.env.REACT_APP_API_URL + "download-pdf";
  };
  return (
    <>
      <footer className={styles["footer"]}>
        <Wrapper>
          <div className={styles["footer-inner"]}>
            <div className={styles["socials"]}>
              <a href="https://www.facebook.com/uif.az/" target="_blank">
                <img src={Facebook} alt="" />
              </a>
              <a href="https://www.instagram.com/uif.az/" target="_blank">
                <img src={Instagram} alt="" />
              </a>
              <a href="https://www.tiktok.com/@uif.az" target="_blank">
                <img src={Tiktok} alt="" />
              </a>
              <a href="https://twitter.com/uif_az/" target="_blank">
                <img src={Twitter} alt="" />
              </a>
              <a href="https://www.youtube.com/@uif_az/" target="_blank">
                <img src={Youtube} alt="" />
              </a>
            </div>
            <div style={{ display: "flex", gap: 20 + "px" }}>
              <a className={styles["contact"]} onClick={() => setPopup(true)}>
                <p>Əlaqə</p>
              </a>
              <a
                className={styles["buttons-xl"]}
                style={{ cursor: "pointer", textDecoration: "none" }}
                href={getLink()}
              >
                Məlumat
              </a>
            </div>
          </div>
        </Wrapper>
      </footer>
      {popup ? (
        <AgreementPopup handleChange={setPopup}>
          <a
            href="tel:147"
            style={{
              display: "flex",
              alignItems: "center",
              textDecoration: "none",
            }}
          >
            <PhoneIcon />
            <p
              style={{
                marginBottom: 8 + "px",
                marginLeft: 5 + "px",
                color: "#8B8A6D",
                fontSize: 24 + "px",
              }}
            >
              147
            </p>
          </a>
          <a
            href="https://wa.me/994502880147"
            className={styles["wp"]}
            style={{
              display: "flex",
              alignItems: "center",
              textDecoration: "none",
            }}
          >
            <WhatsAppLogo />
            <p
              style={{
                marginBottom: 8 + "px",
                marginLeft: 5 + "px",
                color: "#8B8A6D",
                fontSize: 24 + "px",
              }}
            >
              0502880147
            </p>
          </a>
        </AgreementPopup>
      ) : null}
    </>
  );
};

export default Footer;
