import React, { useEffect, useState } from 'react';
import styles from './index.module.css';
import { Link } from 'react-router-dom';
import Wrapper from '../../components/UI/wrapper';
import { BackArrow, DownloadIcon } from '../../icons';
import registrationCheck from '../../api/registrationCheck';
import './style.css';

const FinalResults = () => {
  const [regType, setRegType] = useState(1);
  const [personalRegType, setPersonalRegType] = useState(null);
  const [personalInput, setPersonalInput] = useState(null);
  const [collectiveRegType, setCollectiveRegType] = useState(null);
  const [collectiveInput, setCollectiveInput] = useState(null);
  const [response, setResponse] = useState(null);
  const [responseText, setResponseText] = useState(null);

  const handlePersonalRegType = (e) => {
    setPersonalRegType(e.target.value);
  };
  const handlePersonalInput = (e) => {
    setPersonalInput(e.target.value);
  };
  const handleCollectiveRegType = (e) => {
    setCollectiveRegType(e.target.value);
  };
  const handleCollectiveInput = (e) => {
    setCollectiveInput(e.target.value);
  };
  const getPersonalPlaceholder = () => {
    if (personalRegType === 'fin') return 'Fin kod';
    if (personalRegType === 'birth') return 'Doğum haqqında şəhadətnamə';
    if (personalRegType === 'id') return 'Qeydiyyat kodu';
    return '';
  };
  const getCollectivePlaceholder = () => {
    if (collectiveRegType === 'fin') return 'Fin kod';
    if (collectiveRegType === 'id') return 'Qeydiyyat kodu';
    return '';
  };
  async function getReg() {
    setResponse(null);
    setResponseText(null);
    const query = {
      type: regType === 1 ? 'personal' : 'collective',
      document: personalRegType ? personalRegType : collectiveRegType,
      value: personalInput ? personalInput : collectiveInput,
    };
    const data = await registrationCheck
      .search(query)
      .then((response) => {
        if (
          response.data.message === 'Məlumat tapılmadı!' ||
          response.data.message === 'Səhv sorğu !!!'
        ) {
          setResponse(response.data.message);
        } else {
          // setResponse("Qeydiyyat kodunuz: " + response.data.message);
          // setResponseText(
          //   "Daxil etdiyiniz məlumata əsasən qeydiyyat uğurla tamamlanmışdır."
          // );
          sessionStorage.setItem('userData', JSON.stringify(query));
          window.location = `/final-result/${query.value}`;
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  return (
    <div>
      <Wrapper>
        <Link to={'/'} className={styles['go-back']}>
          <BackArrow />
          <p>Geri qayıt</p>
        </Link>
      </Wrapper>
      <Wrapper>
        <div className={styles['done-container']}>
          <div className={styles['done']}>
            <h3>İştirakla bağlı məlumatı yoxla</h3>
            <div>
              <button
                className={
                  styles[
                    `${regType == 1 ? 'tab-buttons-active' : 'tab-buttons'}`
                  ]
                }
                onClick={() => {
                  setRegType(1);
                  setCollectiveInput('');
                  setCollectiveRegType('');
                  setPersonalInput('');
                  setPersonalRegType('');
                }}>
                Fərdi
              </button>
              <button
                className={
                  styles[
                    `${regType == 2 ? 'tab-buttons-active' : 'tab-buttons'}`
                  ]
                }
                onClick={() => {
                  setRegType(2);
                  setPersonalInput('');
                  setPersonalRegType('');
                  setCollectiveInput('');
                  setCollectiveRegType('');
                }}>
                Kollektiv
              </button>
            </div>
            {regType == 1 ? (
              <>
                <div className={styles['inputs']}>
                  <label htmlFor=''>Qeydiyyatın tipi</label>
                  <select
                    name=''
                    id=''
                    onChange={(e) => handlePersonalRegType(e)}
                    value={personalRegType}>
                    <option value='' disabled selected>
                      Qeydiyyatın tipi
                    </option>
                    <option value='fin_code'>İştirakçının fin kodu</option>
                    <option value='birth_card'>
                      İştirakçının doğum haqqında şəhadətnaməsi
                    </option>
                    <option value='id_code'>İştirakçının qeydiyyat kodu</option>
                  </select>
                </div>
                <div className={styles['inputs']}>
                  <input
                    type={personalRegType === 'id' ? 'number' : 'text'}
                    placeholder={getPersonalPlaceholder()}
                    onChange={(e) => handlePersonalInput(e)}
                    disabled={personalRegType ? false : true}
                    value={personalInput}
                  />
                </div>
              </>
            ) : (
              <>
                <div className={styles['inputs']}>
                  <label htmlFor=''>Qeydiyyatın tipi</label>
                  <select
                    name=''
                    id=''
                    onChange={(e) => handleCollectiveRegType(e)}
                    value={collectiveRegType}>
                    <option value='' disabled selected>
                      Qeydiyyatın tipi
                    </option>
                    <option value='col_fin_code'>
                      Kollektiv rəhbərinin fin kodu
                    </option>
                    <option value='col_id_code'>
                      Kollektivin qeydiyyat kodu
                    </option>
                  </select>
                </div>
                <div className={styles['inputs']}>
                  <input
                    type={collectiveRegType === 'id' ? 'number' : 'text'}
                    placeholder={getCollectivePlaceholder()}
                    onChange={(e) => handleCollectiveInput(e)}
                    disabled={collectiveRegType ? false : true}
                    value={collectiveInput}
                  />
                </div>
              </>
            )}
            <div className={styles['next-container']}>
              <button
                type='button'
                className={styles['next']}
                onClick={() => {
                  getReg();
                }}>
                Yoxla
              </button>
            </div>
            {response ? (
              <>
                {responseText ? <h3>{responseText}</h3> : null}
                <h3>{response}</h3>
              </>
            ) : null}
          </div>
        </div>
      </Wrapper>
    </div>
  );
};

export default FinalResults;
