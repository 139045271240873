import Home from "./pages/home";
import NotFound from "./pages/NotFound";
import finalResults from "./pages/finalResults";
import finalResultDetails from "./pages/finalResultDetails";
const routeItem = (id, title, path, component) => {
  return {
    id,
    title,
    path,
    component,
  };
};

const routes = {
  home: routeItem(1, 'Home', "/", Home),
  finalResults: routeItem(3, 'finalResults', '/final-result', finalResults),
  finalResultDetails : routeItem(4, 'finalResultDetails ', '/final-result/:userId', finalResultDetails ),
  not_found: routeItem(404, '404', '*', NotFound),
};

const routeArr = Object.values(routes);

export { routes, routeArr };
