import React from "react";
import styles from "./index.module.css";

const AgreementPopup = ({ handleChange, error, children }) => {
  return (
    <>
      <div
        className={styles["overlay"]}
        onClick={() => handleChange(false)}
      ></div>
      <div className={styles["fl"]}>
        <div className={styles["container"]}>
          <p className={styles[error ? "error" : null]}>{children}</p>

          <div className={styles["next-container"]}>
            <button
              type="button"
              className={styles["next"]}
              onClick={() => handleChange(false)}
            >
              Oldu
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default AgreementPopup;
