import axios from "../index";

const search = (query) => {
  return axios.get(`search?type=${query.type}&document=${query.document}&value=${query.value}`);
}

const getFinalResults = (data) => {
  return axios.get(`final-results?type=${data.type}&document=${data.document}&value=${data.value}`);
}

export default { search, getFinalResults }