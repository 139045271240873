import React from "react";
import { LoadingIcon } from "../../icons";
import styles from "./index.module.css";

const Loading = () => {
  return (
    <div className={styles["loading"]}>
      <div className={styles["loading-icon"]}>
        <div className={styles["spin"]}>
          <LoadingIcon />
        </div>
      </div>
      <h2 className={styles["loading-text"]}>Məlumatlar yoxlanılır</h2>
    </div>
  );
};

export default Loading;
