import React from "react";
import { Link } from "react-router-dom";
import styles from "./index.module.css";
const NotFound = () => {
  return (
    <div className={styles["not-found"]}>
      <div>
        <h2>404</h2>
        <h2>Səhifə Tapılmadı </h2>
      </div>
      <Link to={"/"}>Ana səhifəyə geri dön</Link>
    </div>
  );
};

export default NotFound;
