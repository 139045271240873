import Wrapper from '../../components/UI/wrapper';
import styles from './index.module.css';
import KidsArtFestLogo from '../../assets/images/kids_art_fest_logo.svg';
import { Link } from 'react-router-dom';
import UserIcon from '../../icons/profile.png';
import UsersIcon from '../../icons/audience.png';
import { useState } from 'react';
import Logo from '../../assets/images/kids_art_fest_logo.svg';
import Loading from '../../components/loading';

const Home = () => {
  const getLink = () => {
    window.location = process.env.REACT_APP_API_URL + 'download-pdf';
  };
  return (
    <>
      <main className={styles['back']}>
        <Wrapper>
          <div className={styles['hero']}>
            <h1>
              Ümummilli lider Heydər Əliyevin <br /> 100 illiyinə həsr edilən{' '}
              <br />
              <span style={{ fontWeight: 700 }}>Uşaq İncəsənət Festivalı</span>
            </h1>
            <div className={styles['buttons']}>
              <Link to={'/final-result'}>Final mərhələsinin nəticələri</Link>
            </div>
            <div className={styles['logo']}>
              <img src={Logo} alt='' />
            </div>
          </div>
        </Wrapper>
      </main>
    </>
  );
};
export default Home;
