import React, { useState } from "react";
import Wrapper from "../UI/wrapper";
import styles from "./index.module.css";
import CultureMinistryLogo from "../../assets/images/culture_ministry_logo.svg";
import HeydarAliyevCenterLogo from "../../assets/images/heydar_aliyev_center_logo.svg";
import EducationMinistryLogo from "../../assets/images/education_ministry_logo.svg";
import { PhoneIcon, WhatsAppLogo } from "../../icons";
import { Link } from "react-router-dom";
import AgreementPopup from "../agreementPopup";

const Navbar = () => {
  const getLink = () => {
    return process.env.REACT_APP_API_URL + "download-pdf";
  };
  const [popup, setPopup] = useState(false);
  return (
    <>
      <nav className={styles["nav"]}>
        <Wrapper>
          <div className={styles["nav-inner"]}>
            <div className={styles["logos"]}>
              <a href="https://culture.gov.az/" target={"_blank"}>
                <img src={CultureMinistryLogo} alt="Culture Ministry Logo" />
              </a>
              <a href="https://heydaraliyevcenter.az/" target={"_blank"}>
                <img
                  src={HeydarAliyevCenterLogo}
                  alt="Heydar Aliyev Center Logo"
                />
              </a>
              <a href="https://edu.gov.az/" target={"_blank"}>
                <img
                  src={EducationMinistryLogo}
                  alt="Education Ministry Logo"
                />
              </a>
            </div>
            <div className={styles["contact-check"]}>
              <a className={styles["contact"]} onClick={() => setPopup(true)}>
                <p>Əlaqə</p>
              </a>
              {/* <Link className={styles["buttons-sm"]} to="/stats">
                Statistika
              </Link> */}
              <a
                target={"_blank"}
                className={styles["buttons-xl"]}
                style={{ cursor: "pointer" }}
                href={getLink()}
              >
                Məlumat
              </a>
            </div>
          </div>
        </Wrapper>
      </nav>
      {popup ? (
        <AgreementPopup handleChange={setPopup}>
          <a
            href="tel:147"
            style={{
              display: "flex",
              alignItems: "center",
              textDecoration: "none",
            }}
          >
            <PhoneIcon />
            <p
              style={{
                marginBottom: 8 + "px",
                marginLeft: 5 + "px",
                color: "#8B8A6D",
                fontSize: 24 + "px",
              }}
            >
              147
            </p>
          </a>
          <a
            href="https://wa.me/994502880147"
            className={styles["wp"]}
            style={{
              display: "flex",
              alignItems: "center",
              textDecoration: "none",
            }}
          >
            <WhatsAppLogo />
            <p
              style={{
                marginBottom: 8 + "px",
                marginLeft: 5 + "px",
                color: "#8B8A6D",
                fontSize: 24 + "px",
              }}
            >
              0502880147
            </p>
          </a>
        </AgreementPopup>
      ) : null}
    </>
  );
};

export default Navbar;
